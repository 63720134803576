import "jspdf-autotable"
import { jsPDF } from "jspdf"
import Loading from "@/components/widgets/Loading.vue"
import VueQRCodeComponent from "vue-qrcode-component"
const _ = require("lodash")

export default {
  data: function () {
    return {
      role: "",
      branch: "",
      allResult: null,
      loading: false,
      qrCode: "sangthai",
      qrCodeBase64: "",
      note: '',
      document: {
        no: "",
        date: "",
        car_number: "",
        license_plate: "",
        payment_terms: null,
      },
      products: [],
      groupByProduct: [],
      branches: [],
      customer: {}
    };
  },
  components: {
    Loading,
    "qr-code": VueQRCodeComponent,
  },
  mounted() {
    this.$nextTick(() => {
      this.onInitData();
    });
    this.getRole()
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res?.data.role
      this.branch = res?.data.branch_id
    },
    generateQRcode() {
      this.loading = true;
      setTimeout(async () => {
        const result = await this.$axios.get(
          this.$host +
            "/api/v1/downloadPreemptionQRcodePDF/" +
            this.$route.params.id,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: "blob",
          }
        );
        const blob = new Blob([result.data]);
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = objectUrl;
        link.setAttribute("download", `QR Stock-${new Date().getTime()}.pdf`);
        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(objectUrl);
        this.loading = false;
      }, 1000);
    },
    async canvasToImg(canvas) {
      return new Promise((resolve, rehect) => {
        var url = canvas.toDataURL();
        resolve(url);
      });
    },
    async onInitIFrame() {
      this.loading = true;
      const canvas = document.querySelectorAll(
        `#qr-code-${this.qrCode} > canvas`
      );
      const canvasUrl = await this.canvasToImg(canvas[0]);

      this.qrCodeBase64 = canvasUrl;
      setTimeout(async () => {
        const processJspdf = async () => {
          return new Promise(async (resolve, reject) => {
            const doc = new jsPDF("p", "mm", "a4");
            doc.addFileToVFS(
              "THSarabunNew.ttf",
              // ttf font file converted to base64
              // following is Consolas with only hex digit glyphs defined (0-9, A-F)
              this.thSarabunNormal
            );
            // add custom font to file
            doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
            doc.setFont("THSarabunNew", "normal");

            doc.addFileToVFS(
              "THSarabunNew Bold.ttf",
              // ttf font file converted to base64
              // following is Consolas with only hex digit glyphs defined (0-9, A-F)
              this.thSarabunBold
            );
            // add custom font to file
            doc.addFont("THSarabunNew Bold.ttf", "THSarabunNew", "bold");
            doc.setFont("THSarabunNew", "bold");

            const vm = this;
            const head = await this.onInitHeader();
            doc.autoTable({
              theme: "plain",
              margin: { top: 8, bottom: 0, left: 8, right: 8 },
              styles: {
                font: "THSarabunNew",
              },
              headStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              bodyStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              footStyles: {
                valign: "top",
                halign: "center",
                minCellHeight: 0,
              },
              head: head,
              body: [],
              willDrawCell(data) {
                if (data.cell.raw && data.section === "head") {
                  if (data.cell.raw.content === "breaklineheader") {
                    const width = doc.internal.pageSize.getWidth() - 16;
                    data.doc.setLineWidth(0.5);
                    data.doc.setDrawColor(192, 192, 192);
                    data.doc.line(
                      data.cell.x,
                      data.cell.y + 3,
                      data.cell.x + width,
                      data.cell.y + 3
                    );
                  }
                  if (data.cell.raw.content === "imageQrCode") {
                    const w = data.cell.contentWidth + 24;
                    data.doc.addImage(
                      vm.qrCodeBase64,
                      "PNG",
                      data.cell.x + w,
                      data.cell.y,
                      36,
                      36
                    );
                  }
                }
              },
            })

            let body = []
            let headTable = []
            if (this.branches.length > 0) {
              //mark
              const data = await this.oncreateTableObject(this.products)              
              for (let index = 0; index < data.length; index++) {
                const element = data[index];

                headTable = await this.onInitHeaderTable(element, true);

                body = await this.onInitBody(element.data);

                doc.autoTable({
                  theme: "plain",
                  margin: { top: 8, bottom: 8, left: 8, right: 8 },
                  startY: doc.lastAutoTable.finalY + 7,
                  styles: {
                    font: "THSarabunNew",
                  },
                  headStyles: {
                    valign: "top",
                    minCellHeight: 0,
                  },
                  bodyStyles: {
                    valign: "top",
                    minCellHeight: 0,
                  },
                  footStyles: {
                    valign: "top",
                    halign: "center",
                    minCellHeight: 0,
                  },
                  head: headTable,
                  body: body,
                  didDrawCell(data) {},
                });
              }
            } else {
              headTable = await this.onInitHeaderTable(element, false)
              body = await this.onInitBody(this.products, true);

              doc.autoTable({
                theme: "plain",
                margin: { top: 8, bottom: 8, left: 8, right: 8 },
                startY: doc.lastAutoTable.finalY + 7,
                styles: {
                  font: "THSarabunNew",
                },
                headStyles: {
                  valign: "top",
                  minCellHeight: 0,
                },
                bodyStyles: {
                  valign: "top",
                  minCellHeight: 0,
                },
                footStyles: {
                  valign: "top",
                  halign: "center",
                  minCellHeight: 0,
                },
                head: headTable,
                body: body,
                didDrawCell(data) {},
              })
            }

            const footer = await this.onInitFooter();
            doc.autoTable({
              theme: "plain",
              margin: { top: 8, bottom: 8, left: 8, right: 8 },
              startY: doc.lastAutoTable.finalY + 5,
              styles: {
                font: "THSarabunNew",
              },
              headStyles: {},
              bodyStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              footStyles: {
                valign: "bottom",
                halign: "center",
                minCellHeight: 0,
              },
              showFoot: "lastPage",
              foot: footer,
            });

            resolve(doc);
          });
        };

        const doc = await processJspdf();
        this.loading = false;
        let element = document.getElementById("inbound-order");
        if (element) {
          element.setAttribute("src", doc.output("bloburl"));
          setTimeout(() => {
            this.loading = false;
            this.onIframePrint();
          }, 1000);
        }
      }, 1000);
    },
    async onInitData() {
      let localApp = this
      this.loading = true
      this.busy = true

      try {
        const param = {
          _id: this.$route.params.id,
        }

        const result = await this.$store.dispatch(
          "Documents/getPreemptionDocument",
          param,
          { root: true }
        )

        this.busy = false
        this.loading = false

        this.branches = result.data.branches
        if (result.data.customer.length > 0) {
          this.customer = result.data.customer[0].detail
        }
        
        if (result?.data) {
          localApp.allResult = result.data

          // Accepts the array and key
          const groupBy = (array, key) => {

            // Return the end result
            return array.reduce((result, currentValue) => {

              // If an array already present for key, push it to the array. Else create an array and push the object
              (result[currentValue[key]] =
                result[currentValue[key]] || []).push(currentValue)

              // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
              return result;

              // empty object is the initial value for result object
            }, {}) 
          }

          // if(result.data.products[0].branch_id != null){
          //   localApp.groupByProduct = groupBy(result.data.products, "branch_id");
          // }
          // else{
            localApp.groupByProduct = groupBy(result.data.products, "destination");
          // }

          let res = result.data.data
          let document_no = "PO-" + res.document_no;
          let date = res.date;
          let payment_terms = res.payment_terms;

          this.note = res.note;
          this.document.no = document_no;
          this.document.date = date;
          this.document.car_number = res.car_number;
          this.document.license_plate = res.license_plate;
          this.document.payment_terms = payment_terms;

          this.products = result.data.products;
          if (this.products.length === 0) {
            this.onExceptionHandler("ไม่พบข้อมูลสินค้า");
          }
        }
      } catch (error) {
        this.onExceptionHandler(error);
        this.loading = false;
        this.busy = false;
        return [];
      }
    },
    oncreateTableObject(data) {
      return new Promise((resolve, reject) => {
        const table = [];
        for (const property in data) {
          const t = _.find(table, { id: data[property].destination });
          if(t){
            t.data.push(data[property]);
          }
          else{
            table.push({
              id: data[property].destination,
              name: data[property].branch_detail ? data[property].branch_detail.name : data[property].branch_detail_old.name,
              data: [data[property]],
          });
          }
        }
        // for (let index = 0; index < data.length; index++) {
        //   const element = data[index];
        //   if (table.length === 0) {
        //     table.push({
        //       id: element.branch_id,
        //       name: element.branch_detail ? element.branch_detail.name : element.branch_detail_old.name,
        //       data: [element],
        //     });
        //   } else {
        //     const t = _.find(table, { id: element.branch_id });
        //     if (t) {
        //       t.data.push(element);
        //     } else {
        //       table.push({
        //         id: element.branch_id,
        //         name: element.branch_detail ? element.branch_detail.name : element.branch_detail_old.name,
        //         data: [element],
        //       });
        //     }
        //   }
        // }
        resolve(table);
      });
    },
    onIframePrint() {
      let iFrame = document.getElementById("inbound-order");
      iFrame.focus();
      iFrame.contentWindow.print();
    },
    createImage() {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const timer = (ms) => new Promise((res) => setTimeout(res, ms));
          const imageUrl = document.getElementById("imageUrl");
          if (imageUrl) {
            for (let index = 0; index < this.products.length; index++) {
              const element = this.products[index];
              const child = document.querySelectorAll(
                `#${element.detail.coil} > img`
              );
              if (child.length > 0) {
                const image = child[0].src;
                const span = document.createElement(
                  `imageUrl-${element.detail.coil}`
                );
                span.setAttribute("id", `imageUrl-${element.detail.coil}`);
                span.innerHTML = image;
                imageUrl.appendChild(span);
                await timer(800);
              }
            }
          }
          resolve(imageUrl);
        }, 1500);
      });
    },
    getBrandName(element) {
      return element.detail.brand !== "OT"
        ? element.detail.brand
        : element.detail.if_other_brand;
    },
    onInitHeaderTable(data, header) {
      return new Promise(async (resolve, reject) => {
        const head = [
          [
            {
              content: header? data.name: 'ลำดับ',
              styles: {
                overflow: "linebreak",
                lineWidth: 0.5,
                fillColor: [220, 220, 220],
                cellPadding: 2,
                cellWidth: header? 25: 15,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "bold",
                halign: "center",
                valign: "top",
              },
            },
            {
              content: "รหัสสินค้า/รายละเอียด",
              styles: {
                lineWidth: 0.5,
                fillColor: [220, 220, 220],
                cellPadding: 2,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "center",
                valign: "top",
              },
            },
            {
              content: "คลัง",
              styles: {
                lineWidth: 0.5,
                fillColor: [220, 220, 220],
                cellPadding: 2,
                cellWidth: 15,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content: "แถว",
              styles: {
                lineWidth: 0.5,
                fillColor: [220, 220, 220],
                cellPadding: 2,
                cellWidth: 15,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content: "น้ำหนัก",
              styles: {
                lineWidth: 0.5,
                fillColor: [220, 220, 220],
                cellPadding: 2,
                cellWidth: 23,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content: "ความยาว",
              styles: {
                lineWidth: 0.5,
                fillColor: [220, 220, 220],
                cellPadding: 2,
                cellWidth: 20,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content: "นน./ม.",
              colSpan: 3,
              styles: {
                lineWidth: 0.5,
                fillColor: [220, 220, 220],
                cellPadding: 2,
                cellWidth: 18,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content: "หมายเหตุ",
              styles: {
                lineWidth: 0.5,
                fillColor: [220, 220, 220],
                cellPadding: 2,
                cellWidth: 20,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
          ],
        ];
        resolve(head);
      });
    },
    onInitHeader() {
      return new Promise(async (resolve, reject) => {
        const head = [
          [
            {
              content: "ใบจ่ายสินค้าภายใน",
              colSpan: 5,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 26,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "bold",
                halign: "left",
                valign: "top",
              },
            },
            {
              content: "imageQrCode",
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 20,
                fontSize: 1,
                textColor: [255, 255, 255],
                cellPadding: 2,
                fontStyle: "bold",
                halign: "right",
                valign: "top",
              },
            },
          ],
          [
            {
              content: "Internal Purchase Order",
              colSpan: 7,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 22,
                textColor: [15, 23, 42],
                cellPadding: {
                  top: -1,
                  bottom: 20,
                },
                fontStyle: "normal",
                halign: "left",
                valign: "top",
              },
            },
          ],
        ];

        const branchs = this.allResult.branches;
        if (branchs.length > 0) {
          for (let index = 0; index < branchs.length; index++) {
            const element = branchs[index];
            if (index === 0) {
              let object1 = {};
              let object2 = {};
              let object3 = {};
              let object4 = {};
              object1 = [
                {
                  content: `รหัสสาขา ${element.detail.code}`,
                  colSpan: 5,
                  styles: {
                    lineWidth: 0,
                    cellWidth: "auto",
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "left",
                    valign: "middle",
                  },
                },
                {
                  content: "เลขที่เอกสาร:",
                  colSpan: 1,
                  styles: {
                    lineWidth: 0,
                    cellWidth: 35,
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "right",
                    valign: "middle",
                  },
                },
                {
                  content: this.document.no,
                  colSpan: 1,
                  styles: {
                    lineWidth: 0,
                    cellWidth: 30,
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "right",
                    valign: "middle",
                  },
                },
              ];
              object2 = [
                {
                  content: `${element.detail.name}`,
                  colSpan: 5,
                  styles: {
                    lineWidth: 0,
                    cellWidth: "auto",
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "left",
                    valign: "middle",
                  },
                },
                {
                  content: "วันที่เอกสาร:",
                  colSpan: 1,
                  styles: {
                    lineWidth: 0,
                    cellWidth: 35,
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "right",
                    valign: "middle",
                  },
                },
                {
                  content: new Date(this.document.date).toLocaleDateString(
                    "th-TH",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  ),
                  colSpan: 1,
                  styles: {
                    lineWidth: 0,
                    cellWidth: 30,
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "right",
                    valign: "middle",
                  },
                },
              ];
              object3 = [
                {
                  content: `${element.detail.address
                    .replace(/[\n\t\r ]+/g, " ")
                    .trim()}`,
                  colSpan: 5,
                  styles: {
                    lineWidth: 0,
                    cellWidth: "auto",
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: {
                      bottom: 2,
                    },
                    fontStyle: "normal",
                    halign: "left",
                    valign: "middle",
                  },
                },
                {
                  content: `เงื่อนไขการชำระ: `,
                  colSpan: 1,
                  styles: {
                    lineWidth: 0,
                    cellWidth: 35,
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "right",
                    valign: "middle",
                  },
                },
                {
                  content:
                    this.document.payment_terms === 1 ? "เงินสด" : "เครดิต",
                  colSpan: 1,
                  styles: {
                    lineWidth: 0,
                    cellWidth: 30,
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "right",
                    valign: "middle",
                  },
                },
              ];
              object4 = [
                {
                  content: "",
                  colSpan: 5,
                  styles: {
                    lineWidth: 0,
                    cellWidth: "auto",
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "left",
                    valign: "middle",
                  },
                },
                {
                  content: `เลขรถ: `,
                  colSpan: 1,
                  styles: {
                    lineWidth: 0,
                    cellWidth: 35,
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "right",
                    valign: "middle",
                  },
                },
                {
                  content:
                    this.document.license_plate &&
                    this.document.license_plate !== "null"
                      ? this.document.license_plate
                      : "",
                  colSpan: 1,
                  styles: {
                    lineWidth: 0,
                    cellWidth: 30,
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "right",
                    valign: "middle",
                  },
                },
              ];
  
              head.push(object1);
              head.push(object2);
              head.push(object3);
              head.push(object4);
            } else {
              let object1 = {};
              let object2 = {};
              let object3 = {};
              let object4 = {};
              object1 = [
                {
                  content: `รหัสสาขา ${element.detail.code}`,
                  colSpan: 7,
                  styles: {
                    lineWidth: 0,
                    cellWidth: "auto",
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "left",
                    valign: "middle",
                  },
                },
              ];
              object2 = [
                {
                  content: `${element.detail.name}`,
                  colSpan: 7,
                  styles: {
                    lineWidth: 0,
                    cellWidth: "auto",
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "left",
                    valign: "middle",
                  },
                },
              ];
              object3 = [
                {
                  content: `${element.detail.address
                    .replace(/[\n\t\r ]+/g, " ")
                    .trim()}`,
                  colSpan: 7,
                  styles: {
                    lineWidth: 0,
                    cellWidth: "auto",
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: {
                      bottom: 2,
                    },
                    fontStyle: "normal",
                    halign: "left",
                    valign: "middle",
                  },
                },
              ];
              object4 = [
                {
                  content: "",
                  colSpan: 7,
                  styles: {
                    lineWidth: 0,
                    cellWidth: "auto",
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "left",
                    valign: "middle",
                  },
                },
              ];
  
              head.push(object1);
              head.push(object2);
              head.push(object3);
              if (index !== branchs.length - 1) {
                head.push(object4);
              }
            }
          }
        } else {
          let object1 = {};
          let object2 = {};
          let object3 = {};
          let object4 = {};
          
          object1 = [
            {
              content: `รหัสลูกค้า ${this.customer.code}`,
              colSpan: 5,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: "เลขที่เอกสาร:",
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 35,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: this.document.no,
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 30,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
          ];
          object2 = [
            {
              content: `${this.customer.name}`,
              colSpan: 5,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: "วันที่เอกสาร:",
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 35,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: new Date(this.document.date).toLocaleDateString(
                "th-TH",
                {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                }
              ),
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 30,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
          ];
          object3 = [
            {
              content: `${this.customer.address
                .replace(/[\n\t\r ]+/g, " ")
                .trim()}`,
              colSpan: 5,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: {
                  bottom: 2,
                },
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: `เงื่อนไขการชำระ: `,
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 35,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content:
                this.document.payment_terms === 1 ? "เงินสด" : "เครดิต",
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 30,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
          ];
          object4 = [
            {
              content: "",
              colSpan: 5,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: `เลขรถ: `,
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 35,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content:
                this.document.license_plate &&
                this.document.license_plate !== "null"
                  ? this.document.license_plate
                  : "",
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 30,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
          ];

          head.push(object1);
          head.push(object2);
          head.push(object3);
          head.push(object4);
        }        

        head.push([
          {
            content: "breaklineheader",
            colSpan: 7,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 1,
              textColor: [255, 255, 255],
              cellPadding: 0,
              fontStyle: "bold",
              halign: "center",
              valign: "top",
            },
          },
        ]);
        resolve(head);
      });
    },
    onInitBody(items, is_customer=false) {
      return new Promise(async (resolve, reject) => {
        const data = items;
        const body = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let obj = {};
          obj = [
            {
              content: index + 1,
              styles: {
                cellWidth: is_customer? 15: 25,
                overflow: "linebreak",
                lineWidth: {
                  left: 0.5,
                  right: 0.5,
                  bottom: 0.5,
                },
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1,
                },
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content:
                // "L" +
                // element.detail.lot_no +
                // " " +
                // this.getBrandName(element) +
                // " " +
                // element.detail.coil +
                // " " +
                // element.detail.size +
                // " " +
                // element.detail.color,
                this.getContent(element),
              styles: {
                cellWidth: "auto",
                lineWidth: {
                  left: 0.5,
                  right: 0.5,
                  bottom: 0.5,
                },
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1,
                },
                fontStyle: "normal",
                halign: "left",
                valign: "top",
              },
            },
            {
              content: element.detail.warehouse ? element.detail.warehouse : "",
              styles: {
                lineWidth: {
                  left: 0.5,
                  right: 0.5,
                  bottom: 0.5,
                },
                fontSize: 15,
                cellWidth: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1,
                },
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content: element.detail.row ? element.detail.row : "",
              styles: {
                lineWidth: {
                  left: 0.5,
                  right: 0.5,
                  bottom: 0.5,
                },
                cellWidth: 15,
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1,
                },
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content: parseFloat(element.detail.net_weight).toLocaleString(
                "th-TH",
                {
                  minimumFractionDigits: 2,
                }
              ),
              styles: {
                lineWidth: {
                  left: 0.5,
                  right: 0.5,
                  bottom: 0.5,
                },
                fontSize: 15,
                cellWidth: 23,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1,
                },
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content:
                parseInt(element.detail.length).toLocaleString("th-TH") + "M",
              styles: {
                lineWidth: {
                  left: 0.5,
                  right: 0.5,
                  bottom: 0.5,
                },
                fontSize: 15,
                cellWidth: 20,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1,
                },
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },          
            {
              content: (
                parseFloat(element.detail.net_weight) /
                parseFloat(element.detail.length)
              ).toFixed(3),
              colSpan: 3,
              styles: {
                lineWidth: {
                  left: 0.5,
                  right: 0.5,
                  bottom: 0.5,
                },
                cellWidth: 18,
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1,
                },
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content:
                element.detail.note && element.detail.note != "null"
                  ? element.detail.note
                  : "",
              styles: {
                lineWidth: {
                  left: 0.5,
                  right: 0.5,
                  bottom: 0.5,
                },
                fontSize: 15,
                cellWidth: 20,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1,
                },
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
          ];
          body.push(obj);
        }
        resolve(body);
      });
    },
    onInitFooter() {
      return new Promise((resolve, reject) => {
        const footer = [];
        const document_note = [
          {
            content: `หมายเหตุ: ${this.note ? this.note : ""}`,
            styles: {
              lineWidth: 0,
              // cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                top: 10,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "top",
            },
          },
          {
            content: `ทะเบียนรถ ${
              this.allResult.data.car_number
                ? this.allResult.data.car_number
                : ".................................................."
            }`,
            styles: {
              lineWidth: 0,
              // cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                top: 10,
              },
              fontStyle: "normal",
              halign: "right",
              valign: "middle",
            },
          },
        ];

        const weightextraTotal = [
          {
            content: `น้ำหนักเพิ่มเติม = ${
              this.allResult?.data.more_weight
                ? parseInt(this.allResult?.data.more_weight).toLocaleString(
                    "th-TH"
                  )
                : 0
            } kg.`,
            styles: {
              lineWidth: 0,
              // cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                top: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
          {
            content:
              "บริษัทขนส่ง..................................................",
            styles: {
              lineWidth: 0,
              // cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                top: 5,
              },
              fontStyle: "normal",
              halign: "right",
              valign: "middle",
            },
          },
        ];

        const weightTotal = [
          {
            content: `น้ำหนักทั้งหมด = ${parseFloat(
              this.sumOfGross
            ).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
            })} kg.`,
            styles: {
              lineWidth: 0,
              // cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: 0,
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
          {
            content:
              "วัน/เดือน/ปี..................................................",
            styles: {
              lineWidth: 0,
              // cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: 0,
              fontStyle: "normal",
              halign: "right",
              valign: "middle",
            },
          },
        ];

        const grandTotal = [
          {
            content: `น้ำหนักรวม = ${parseFloat(
              this.sumOfGross + this.allResult?.data.more_weight
            ).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
            })} kg.`,
            styles: {
              lineWidth: 0,
              // cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                bottom: 1,
              },
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
            },
          },
          {
            content:
              "ผู้ตรวจสอบ..................................................",
            styles: {
              lineWidth: 0,
              // cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "right",
              valign: "middle",
            },
          },
        ];

        const transport1 = [
          {
            content: `ทะเบียนรถ ${
              this.allResult.data.car_number
                ? this.allResult.data.car_number
                : "........................................."
            }`,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                top: 10,
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];

        const transport2 = [
          {
            content:
              "บริษัทขนส่ง..................................................",
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];

        const transport3 = [
          {
            content:
              "วัน/เดือน/ปี..................................................",
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];

        const transport4 = [
          {
            content:
              "ผู้ตรวจสอบ..................................................",
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];

        footer.push(document_note);
        footer.push(weightextraTotal);
        footer.push(weightTotal);
        footer.push(grandTotal);
        // footer.push(transport1);
        // footer.push(transport2);
        // footer.push(transport3);
        // footer.push(transport4);

        resolve(footer);
      });
    },
    getContent(element) {
      if (element.detail.brand === "BHP") {
        return element.detail.size + " " + element.detail.color + " " + this.getBrandName(element) + "\nB" + element.detail.lot_no + " " + (element.detail.code ? element.detail.code : '') + " " + element.detail.coil

      } else if (element.detail.brand === "JJ") {
        return element.detail.size +
          " " +
          element.detail.color +
          " " +
          this.getBrandName(element) +
          "\nJ" +
          element.detail.lot_no +
          " " +
          (element.detail.code ? element.detail.code : '') +
          " " +
          element.detail.coil
      } else if (element.detail.brand === "EM-STAR") {
        return  element.detail.size +
          " " +
          element.detail.color +
          " " +
          this.getBrandName(element) +
          "\nE" +
          element.detail.lot_no +
          " " +
          (element.detail.code ? element.detail.code : '') +
          " " +
          element.detail.coil
      } else {
        return element.detail.size +
          " " +
          element.detail.color +
          " " +
          this.getBrandName(element) +
          "\nL" +
          element.detail.lot_no +
          " " +
          (element.detail.code ? element.detail.code : '') +
          " " +
          element.detail.coil
      }
    },
  },
  computed: {
    sumOfWeight() {
      return this.products.reduce((sum, transaction) => {
        return (sum += parseInt(transaction.detail.net_weight));
      }, 0);
    },
    sumOfGross() {
      return this.products.reduce((sum, transaction) => {
        return (sum += parseInt(transaction.detail.gross_weight));
      }, 0);
    },
    allWeight() {
      return (
        parseInt(this.sumOfGross) + parseInt(this.allResult.data.more_weight)
      );
    }
  }
}
